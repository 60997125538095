import { useState } from "react";

/**
 *
 * @param {string} key
 * @param {string||null} value
 */
export const useLocalStorage = (key, value) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(key, JSON.stringify(value));
                return value;
            }
        } catch (err) {
            return value;
        }
    });
    const setValue = (newValue) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {}
        setStoredValue(newValue);
    };
    return [storedValue, setValue];
};